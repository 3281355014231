/* eslint-disable */
import md5 from 'md5';
import Vue from 'vue';
import resetApi from './axios-promise';
import config from '../../../config';

/**
 * mockuai.com 请求api接口封装
 *
 * @author Allen
 * @requires lib.storage[lib.storage] lib.login
 *
 */
(function (win, lib) {
  const APP_ENV = process.env.VUE_APP_ENV;
  const { APP_KEY, APP_PWD, API_URL, H5_URL } = config[APP_ENV];

  // 异常处理的配置信息
  const exceptionConfig = {
    exceptionCodeList: [50001, 50002, 50003, 50004, 50005, 50006], // 异常code值
    maxCount: 10, // 异常总次数
    intervalTime: 2000, // 异常间隔5秒
  };

  // helper methods
  function sortObject(o) {
    const sorted = {};
      let key;
      const a = [];

    for (key in o) {
      if (o.hasOwnProperty(key)) {
        a.push(key);
      }
    }

    a.sort();

    for (key = 0; key < a.length; key++) {
      sorted[a[key]] = o[a[key]];
    }
    return sorted;
  }

  function makeSign(params) {
    const app_pwd = lib.api.appPwd;
    params = sortObject(params);

    let dictionary = app_pwd;
    for (const key in params) {
      dictionary += `${key}=${params[key]}&`;
    }
    dictionary = dictionary.slice(0, -1);
    dictionary += app_pwd;

    const api_sign = md5(dictionary);
    return api_sign;
  }

  function makeSignWithOutFile(params) {
    const app_pwd = lib.api.appPwd;
    params = sortObject(params);

    let dictionary = app_pwd;
    for (const key in params) {
      if (key == 'file') {
        continue;
      }
      dictionary += `${key}=${params[key]}&`;
    }
    dictionary = dictionary.slice(0, -1);
    dictionary += app_pwd;

    const api_sign = md5(dictionary);
    return api_sign;
  }

  function getSession(callback) {
    const data = {
      format: 'json',
      app_key: lib.api.appKey,
      timestamp: Math.floor(Date.now() / 1000),
    };
    $.getJSON(
      `${lib.api.apiUrl}wdzg/auth/session_token/get?format=json&app_key=${data.app_key}&timestamp=${
        data.timestamp
      }&api_sign=${makeSign(data)}`,
      response => {
        lib.api.session_token = response.data.session_token;
        lib.storage.set('session_token', lib.api.session_token);
        callback && callback();
      },
    );
  }

  function handleResponse(opts, data) {
    const currentDateTime = new Date().getTime();
    // 异常处理
    if (exceptionConfig.exceptionCodeList.includes(data.code)) {
      let { exceptionStartTime } = lib.api;
      let { currentExceptionCount } = lib.api;
      if (!exceptionStartTime) {
        exceptionStartTime = currentDateTime;
      }
      currentExceptionCount++;
      console.log(
        `${currentDateTime - exceptionStartTime} 毫秒内异常数量 ${currentExceptionCount}`,
      );
      // 间隔时间内异常数大于最大值，重新登陆
      if (
        currentDateTime - exceptionStartTime < exceptionConfig.intervalTime
        && currentExceptionCount >= exceptionConfig.maxCount
      ) {
        lib.api.isWaiting = true;
        lib.login && lib.login.goLogin();
        return;
      }
      // 如果异常间隔超超过设置的时间，重新开始记录
      if (currentDateTime - exceptionStartTime > exceptionConfig.intervalTime) {
        exceptionStartTime = currentDateTime;
        currentExceptionCount = 0;
      }
      lib.api.exceptionStartTime = exceptionStartTime;
      lib.api.currentExceptionCount = currentExceptionCount;
    }

    switch (data.code) {
      case 10000:
        // 调用成功
        opts.success && opts.success(data);
        break;
      case 50001:
      // 缺少参数session_token
      case 50002:
        if (lib.api.needSessionToken) {
          lib.api.requestQueue.push(opts.fn);
          return;
        }
        lib.api.needSessionToken = true;
        // session_token无效或已过期
        getSession(() => {
          opts.fn.call();
          lib.api.needSessionToken = false;
          lib.api.callRequestQueue();
        });
        break;
      case 50003:
      case 50004:
      case 50005:
      case 50006:
      case 50007:
        console.log('hhhhhhh')
        // access_token失效或已过期直接重新登录，为了安全性不拿refresh_token去换
        lib.api.needSessionToken = false;
        lib.api.requestQueue = [];
        lib.login.goLogin();
        break;
      default:
        // 其他情况
        opts.error && opts.error(data);
    }
  }

  function func_ajax(opts, ajaxType, isTms) {
    if (lib.api.isWaiting) {
      return;
    }
    if (mockOrAppCall(opts, ajaxType)) return;

    const url = opts.api.indexOf('http') === -1 ? lib.api.apiUrl : '';
    const data = $.extend(true, {}, opts.data);
    const access_token = lib.storage.get('access_token');

    data.format = 'json';
    data.app_key = lib.api.appKey;
    data.timestamp = '1523687615' || Math.floor(Date.now() / 1000);
    data.session_token = lib.storage.get('session_token');
    if (access_token) {
      data.access_token = access_token;
    }

    // 清除空参数
    for (var key in data) {
      if (data[key] === undefined || data[key] === null || data[key] === '') {
        delete data[key];
      }
    }

    // 解决post提交，换行报签名错误
    if (ajaxType == 'POST') {
      for (var key in data) {
        try {
          data[key] = data[key].replace(/\r\n/g, '\n').replace(/\n/g, '\r\n');
        } catch (e) {}
      }
    }
    data.api_sign = makeSign(data);
    if (ajaxType == 'GET') {
      addOperationLog(url, ajaxType, opts.api, data);
      Vue.http
        .get(`${url}${opts.api}`, {
          params: data,
          responseType: 'json',
          emulateJSON: true,
        })
        .then(
          response => {
            handleResponse(opts, response.body);
          },
          error => {
            opts.error && opts.error(null, error.status, error.statusText);
          },
        )
        .then(response => {
          opts.complete && opts.complete(response);
        });
    } else if (ajaxType == 'POST') {
      addOperationLog(url, ajaxType, opts.api, data);
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      // 真实请求
      Vue.http
        .post(`${url}${opts.api}`, formData, {
          responseType: 'json',
        })
        .then(
          response => {
            handleResponse(opts, response.body);
          },
          error => {
            opts.error && opts.error(error, error.status, error.statusText);
          },
        )
        .then(response => {
          opts.complete && opts.complete(response);
        });
    }
  }

  // 添加日志
  function addOperationLog(url, ajaxType, api, postData) {
    if (ajaxType === 'POST' || lib.api.addOperationLogGetRequestList.includes(api)) {
      const params = {
        format: postData.format,
        app_key: postData.app_key,
        timestamp: postData.timestamp,
        session_token: postData.session_token,
        access_token: postData.access_token,
      };
      const logFormData = new FormData();
      params.operation_content = api;
      params.api_sign = makeSign(params);
      for (const key in params) {
        logFormData.append(key, params[key]);
      }
      // Vue.http.post(`${url}` + 'ec/m/user/admin/operation/add', logFormData, {
      //   responseType: 'json',
      // });
    }
  }

  function file_ajax(opts, ajaxType) {
    const url = opts.api.indexOf('http') === -1 ? lib.api.apiUrl : '';
    const data = $.extend(true, {}, opts.data);
    const access_token = lib.storage.get('access_token');

    data.format = 'json';
    data.app_key = lib.api.appKey;
    data.timestamp = Math.floor(Date.now() / 1000);
    data.session_token = lib.storage.get('session_token');
    if (access_token) {
      data.access_token = access_token;
    }
    data.api_sign = makeSignWithOutFile(data);

    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    if (typeof opts.progress === 'function') {
      $.ajax({
        url: url + opts.api,
        type: 'POST',
        data: formData,
        dataType: 'json',
        processData: false,
        contentType: false,
        cache: false,
        xhr() {
          const xhr = $.ajaxSettings.xhr();
          if (opts.progress && xhr.upload) {
            xhr.upload.addEventListener('progress', opts.progress, false);
            return xhr;
          }
        },
        success(data) {
          handleResponse(opts, data);
        },
      }).done((resp) => {
        console.log(resp);
      });
    } else {
      $.ajax({
        url: url + opts.api,
        type: 'POST',
        data: formData,
        dataType: 'json',
        processData: false,
        contentType: false,
        cache: false,
        success(data) {
          // 状态码处理
          handleResponse(opts, data);
        },
        error(xhr, errorType, error) {
          opts.error && opts.error(xhr, errorType, error);
        },
        complete(xhr, status) {
          opts.complete && opts.complete(xhr, status);
        },
      });
    }
  }

  // 非正常请求处理
  function mockOrAppCall(opts, ajaxType) {
    // 支持mock数据
    if (opts.mock) {
      const mockData = opts.mock;

      $.getJSON(mockData.path, data => {
        if (mockData.error) {
          opts.error && opts.error(data);
        } else {
          opts.success && opts.success(data);
        }
        opts.complete && opts.complete();
      });
      return true;
    }

    return false;
  }

  // console.log('config', config);

  // console.log('process.env.API_URL', process.env);

  // exports
  lib.api = {
    // 是否需要全新的session_token
    needSessionToken: false,
    // 等待请求队列
    requestQueue: [],
    addOperationLogGetRequestList: [
      'ec/m/celebrity/mobile/get',
      'ec/m/star/mobile/get',
      'ec/m/supplier/brand/user/mobile/get',
    ],
    inited: false,
    h5Url: H5_URL,
    appKey: APP_KEY,
    appPwd: APP_PWD,
    apiUrl: API_URL,
    apiEnv: APP_ENV,
    // 排队相关
    isWaiting: false, // 是否在排队中
    exceptionStartTime: 0, // 异常开始时间
    currentExceptionCount: 0, // 当前异常数量
    // 重置状态
    reset() {
      this.needSessionToken = false;
      this.requestQueue = [];

      this.inited = false;
    },
    init() {
      // 防止重复设置
      if (this.inited) return;
      this.inited = true;

      this.appKey = localStorage.getItem('app_key') || this.appKey;
      this.appPwd = localStorage.getItem('app_pwd') || this.appPwd;
    },
    get(opts) {
      this.init();

      const requestMethod = 'GET';

      const fn = function () {
        func_ajax(opts, requestMethod);
      };
      opts.fn = fn;

      this.callRequest(opts);
    },
    post(opts) {
      this.init();

      const requestMethod = 'POST';

      const fn = function () {
        func_ajax(opts, requestMethod);
      };
      opts.fn = fn;

      this.callRequest(opts);
    },
    file(opts) {
      this.init();

      const requestMethod = 'POST';

      const fn = function () {
        file_ajax(opts, requestMethod);
      };
      opts.fn = fn;

      this.callRequest(opts);
    },
    callRequest(opts) {
      lib.api.session_token = lib.storage.get('session_token');
      // 没有session_token则去获取session_token
      if (!lib.api.session_token && !lib.api.needSessionToken) {
        lib.api.needSessionToken = true;
        getSession(() => {
          opts.fn.call();
          lib.api.needSessionToken = false;
          lib.api.callRequestQueue();
        });
      } else {
        // 如果需要登录并且没有登录则去登录
        if (opts.needLogin && !!lib.login.isLogin()) {
          lib.login.goLogin();
          return;
        }
        // 验证请求状态，判断是立即执行还是放入队列
        !lib.api.needSessionToken ? opts.fn() : this.requestQueue.push(opts.fn);
      }
    },
    callRequestQueue() {
      const fns = this.requestQueue;
      for (let i = 0, len = fns.length; i < len; i++) {
        fns[i].call();
      }
      // reset
      this.requestQueue = [];
    },
  };

  lib.api.get = resetApi(lib.api.get.bind(lib.api));
  lib.api.post = resetApi(lib.api.post.bind(lib.api));
  lib.api.file = resetApi(lib.api.file.bind(lib.api));
}(window, window.lib || (window.lib = {})));
