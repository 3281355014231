/* eslint-disable */
import Vue from 'vue'
import { encode } from 'js-base64'
import 'normalize.css'
import 'element-ui/lib/theme-chalk/index.css'
import VueResource from 'vue-resource'
import VueResourceProgressBarInterceptor from 'vue-resource-progressbar-interceptor'
import VueProgressBar from 'vue-progressbar'
import Viewer from 'v-viewer'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'viewerjs/dist/viewer.css'
import 'swiper/css/swiper.min.css'
import './style/fullpage.css'

import {
    Message,
    MessageBox,
    Input,
    Form,
    FormItem,
    Button,
    Select,
    Option,
    Radio,
    RadioGroup,
    Cascader,
    Upload,
    Tabs,
    Dialog,
    TabPane,
    Table,
    TableColumn,
    Pagination,
    Icon,
} from 'element-ui'
import { isClient, loadFont } from '@/utils'
import './style/common.less'
import './global.less'

import App from './App.vue'
import createRouter from './router'
import Bus from './utils/event-bus'

// 客户端编译才渲染service-work
isClient(() => {
    require('./js/common/api')
    require('./js/common/login')
    require('./js/common/storage')
    const qrcode = require('qrcodejs2')
    Vue.prototype.$bus = Bus
    Vue.prototype.$qrcode = qrcode
    loadFont('https://cdn.mockuai.com/tms/DINCond-Medium.otf')
})

import createStore from './store'

// isClient(() => {
//   // require('fullpage.js/vendors/scrolloverflow')
//   // require('./fullpage.scrollHorizontally.min')
//   const VueFullPage = require('vue-fullpage.js')
//   Vue.use(VueFullPage)
// })

let used = false

function useComponents() {
    if (used) return
    used = true
    Vue.use(Input)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Button)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Cascader)
    Vue.use(Radio)
    Vue.use(RadioGroup)
    Vue.use(Upload)
    Vue.use(Tabs)
    Vue.use(TabPane)
    Vue.use(Table)
    Vue.use(Dialog)
    Vue.use(TableColumn)
    Vue.use(Pagination)
    Vue.use(Icon)
    Vue.prototype.$message = Message
    Vue.prototype.$messageBox = MessageBox

    Vue.use(VueResource)
    Vue.use(VueProgressBar)
    Vue.use(VueResourceProgressBarInterceptor)
    Vue.use(Viewer)
    Vue.use(VueResourceProgressBarInterceptor, {
        latencyThreshold: 50,
        responseLatency: 50,
    })
    Vue.use(VueAwesomeSwiper)
}
Vue.component(MessageBox.name, MessageBox)
useComponents()

Vue.config.productionTip = false

Vue.prototype.client = isClient

export default function createApp(context) {
    // console.log('context', context.type);
    const store = createStore()
    const router = createRouter(context, store)

    const app = {
        router,
        store,
        head: {},
        initialState: {
            fullpageKey: encode('4D11C3F5-EC4A4533-AD657D67-11F02983'),
        },
        render: (h) => h(App),
    }

    return app
}
console.log(
    '环境',
    process.env.VUE_APP_ENV,
    process.env.VUE_APP_WB,
    process.env.NODE_ENV,
    process.env.VAPPER_ENV
)

// 不是通过vapper.config.js入口进行服务端渲染
// 经过的是vue.config.js入口客户端渲染
if (!process.env.VAPPER_ENV) {
    new Vue(createApp()).$mount('#app')
}