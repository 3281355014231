<template>
  <header
    v-if="visible"
    id="page-header"
    class="header-wrapper"
    :class="wrapperClass"
    :style="wrapperStyle"
    ref="header"
    @mouseleave="hideSubNav"
  >
    <div class="header-container">
      <div class="logo-wrap">
        <a href="/">
          <img class="logo" :src="logo" alt="" />
        </a>
      </div>
      <ul class="nav">
        <li
          v-for="(nav, index) in navs"
          :key="index"
          class="nav-item"
          :class="
            $route.path === nav.href ||
            ($route.path === '/source-factory' && nav.subNavIndex === 1) ||
            (nav.subs &&
              nav.subs.map((item) => item.href).includes($route.path))
              ? 'active'
              : ''
          "
          @mouseenter="showSubNav(nav)"
        >
          <a v-if="nav.href" :href="nav.href">{{ nav.text }}</a>
          <template v-else>
            <span>{{ nav.text }}</span>
            <img
              :src="
                subnavVisible && subnavIndex === nav.subNavIndex
                  ? arrowUpIcon
                  : arrowDownIcon
              "
              alt=""
              class="icon"
            />
            <ul
              v-show="subnavVisible && subnavIndex === nav.subNavIndex"
              class="sub-nav-list"
            >
              <li v-for="(subnav, i) in nav.subs" :key="i" class="sub-nav-item">
                <a :href="subnav.href">{{ subnav.text }}</a>
              </li>
            </ul>
          </template>
        </li>
      </ul>
      <!-- <div class="notice-wrap">
        <div class="icon-box">
          <img :src="hornIcon" alt="" class="horn-icon">
          <img :src="voiceIcon" alt="" class="voice-icon">
        </div>
        <swiper 
          class="notice-swiper"
          ref="noticeSwiper" 
          :options="swiperOption"
        >
          <swiper-slide v-for="(item, index) in notices" :key="index">
            <a :href="item.link" class="text" @click="onNoticeClick(item)">
              <span>{{ item.msg }}</span>
            </a>
          </swiper-slide>
        </swiper>
      </div> -->
      <!-- <a
        v-if="!withoutLogin"
        target="_blank"
        href="/login"
        class="login-btn"
        @click="goLogin"
      >
        登录
      </a> -->
    </div>
  </header>
</template>
<script>
// import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

const themesObj = {
  normal: "",
  transparent: "header-wrapper-transparent",
  dark: "header-wrapper-dark",
};

export default {
  head: {
    meta: [{ name: "viewport", content: "width=1440" }],
  },
  components: {
    // Swiper,
    // SwiperSlide,
  },
  // directives: {
  //   swiper: directive
  // },
  props: {
    // theme: {
    //   type: String,
    //   default: 'normal'
    // }
    type: {
      type: String,
      default: "layout",
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    top: {
      type: Number,
      default: 0,
    },
    withoutLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      theme: "normal",
      navs: [
        {
          href: "/",
          text: "首页",
        },

        {
          href: "/anchor",
          text: "主播合作",
        },
        {
          href: "/store",
          text: "商家合作",
        },
        // {
        //   text: "加盟代言",
        //   // text: '代理合作',
        //   subNavIndex: 3,
        //   subs: [
        //     {
        //       href: "/channel-join",
        //       text: "渠道加盟",
        //       // text: '渠道代理'
        //     },
        //     {
        //       href: "/partner",
        //       text: "代言合作",
        //     },
        //   ],
        // },
        {
          href: "/recruit",
          text: "加入魔筷",
        },
        {
          href: "/news",
          text: "资讯中心",
        },
      ],
      swiperOption: {
        notNextTick: true,
        direction: "vertical",
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
      },
      notices: [
        {
          link: "/scrm",
          msg: "魔筷SCRM！酷管家强势袭来",
        },
      ],
      subnavVisible: false,
      subnavIndex: 1,
    };
  },
  computed: {
    visible() {
      if (this.type === "layout") {
        const { withoutLayout, withoutHeader } = this.$route.meta;
        if (withoutLayout || withoutHeader) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    logo() {
      return this.theme !== "normal"
        ? "https://mktv-in-cdn.mockuai.com/16233799092722590.png"
        : "https://mktv-in-cdn.mockuai.com/16233799407883778.png";
    },
    noticeIcon() {
      return this.theme === "transparent" || this.theme === "dark"
        ? "https://mktv-in-cdn.mockuai.com/16233833638493464.png"
        : "https://mktv-in-cdn.mockuai.com/16237293043722794.png";
    },
    hornIcon() {
      return this.theme === "transparent" || this.theme === "dark"
        ? "https://mktv-in-cdn.mockuai.com/16276367109513591.png"
        : "https://mktv-in-cdn.mockuai.com/16276364096182452.png";
    },
    voiceIcon() {
      return this.theme === "transparent" || this.theme === "dark"
        ? "https://mktv-in-cdn.mockuai.com/16276367109449966.png"
        : "https://mktv-in-cdn.mockuai.com/16276364096139541.png";
    },
    arrowDownIcon() {
      if (this.theme === "transparent" || this.theme === "dark") {
        return "https://mktv-in-cdn.mockuai.com/16233833989872063.png";
      } else {
        return "https://mktv-in-cdn.mockuai.com/16237479689279272.png";
      }
    },
    arrowUpIcon() {
      if (this.theme === "transparent" || this.theme === "dark") {
        return "https://mktv-in-cdn.mockuai.com/16263213730601161.png";
      } else {
        return "https://mktv-in-cdn.mockuai.com/16237479792195074.png";
      }
    },
    wrapperClass() {
      let classArr = [themesObj[this.theme]];
      if (
        this.subnavVisible &&
        this.$route.path !== "/" &&
        this.$route.path !== "/login"
      ) {
        classArr.push("header-expanded");
      }
      return classArr;
    },
    wrapperStyle() {
      let style = {
        height: "90px",
        top: `${this.top}px`,
      };
      if (
        (this.$route.path === "/" || this.$route.path === "/login") &&
        this.subnavVisible
      ) {
        style.height = "220px";
      }
      if (!this.fixed) {
        style.position = "relative";
      }
      return style;
    },
  },
  created() {
    // this.theme = ['/', '/login'].includes(this.$route.path) ? 'transparent' : 'normal'
    if (this.$route.path === "/") {
      this.theme = "transparent";
    } else if (this.$route.path === "/login") {
      this.theme = "dark";
    } else {
      this.theme = "normal";
    }
  },
  mounted() {
    this.$bus.$on("headerModeChange", this.changeMode);
  },
  destroyed() {
    this.$bus.$off("headerModeChange", this.changeMode);
  },
  methods: {
    changeMode(mode) {
      this.theme = mode;
    },

    showSubNav(nav) {
      console.log("subnav");
      if (nav.href) {
        return;
      }
      if (
        (this.$route.path === "/" || this.$route.path === "/login") &&
        this.theme === "transparent"
      ) {
        this.theme = "dark";
      }
      this.$refs.header.style.overflow = "visible";
      this.subnavVisible = true;
      this.subnavIndex = nav.subNavIndex;
    },

    hideSubNav() {
      if (this.$route.path === "/") {
        const currentSection = localStorage.getItem("currentSection");
        console.log("currentSection", currentSection, typeof currentSection);
        // this.theme = (!currentSection || currentSection === '0') ? 'transparent' : 'normal'
        if (!currentSection || currentSection === "0") {
          this.theme = "transparent";
        } else if ([2, 4, 5].includes(+currentSection)) {
          this.theme = "dark";
        } else {
          this.theme = "normal";
        }
      }
      this.$refs.header.style.overflow = "auto hidden";
      this.subnavVisible = false;
    },

    onNoticeClick(item) {
      console.log(item);
      if (window._czc) {
        window._czc.push(["_trackEvent", "头部导航公告", "点击", item.msg]);
      }
    },
    goLogin() {
      console.log("lll");
      if (window._czc) {
        window._czc.push(["_trackEvent", "头部导航登录按钮", "点击"]);
      }
    },
  },
};
</script>


<style lang="less" scoped>
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header-wrapper {
  transition: height 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: top 0.4s;
  box-sizing: border-box;
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  // padding: 0 240px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #fff;
  user-select: none;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow: auto hidden;
  &.header-expanded {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -130px;
      width: 100%;
      height: 130px;
      background-color: #fff;
    }
  }
  .header-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    // min-width: 960px;
    min-width: 900px;
    height: 90px;
    margin: 0 30px;
  }
  .logo-wrap {
    margin-right: 40px;
    .logo {
      width: 254px;
      height: 37px;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex: 1;
    .nav-item {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 60px;
      line-height: 25px;
      color: #333;
      font-family: PingFangSC-Regular, PingFang SC;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      white-space: nowrap;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: -4px;
          transform: translateX(-50%);
          width: 20px;
          height: 2px;
          background-color: #ff6600;
        }
      }
      > span {
        &:hover {
          font-weight: 500;
        }
      }
      a {
        text-decoration: none;
        color: #333;
        &:hover {
          font-weight: 500;
        }
      }
      .icon {
        width: 12px;
        height: 7px;
        margin-left: 4px;
      }
      .sub-nav-list {
        position: absolute;
        z-index: 100;
        top: 60px;
        .sub-nav-item {
          margin-bottom: 17px;
          // &:last-child {
          //   margin-bottom: 0;
          // }
          a {
            line-height: 25px;
            font-size: 16px;
            font-weight: 400;
            color: #666;
            &:hover {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .notice-wrap {
    display: flex;
    align-items: center;
    height: 20px;
    margin-left: 42px;
    margin-right: 46px;
    overflow: hidden;
    .icon {
      width: 20px;
      height: 16px;
      margin-right: 4px;
    }
    .icon-box {
      display: flex;
      align-items: center;
      margin-right: 4px;
      .horn-icon {
        margin-right: 2px;
        width: 12px;
        height: 16px;
      }
      .voice-icon {
        width: 8px;
        height: 15px;
        animation: blink 0.5s linear 0.2s infinite;
      }
    }
    .notice-swiper {
      height: 20px;
    }
    .swiper-slide {
      max-width: 209px;
      overflow: hidden;
      white-space: nowrap;
      .text {
        display: block;
        text-decoration: none;
        line-height: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        max-width: 209px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .login-btn {
    box-sizing: border-box;
    width: 88px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #333;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    color: #333;
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: 14px;
    font-weight: 500;
    white-space: pre;
    cursor: pointer;
    &:hover {
      border-color: #ff6600;
      background-color: #ff6600;
      color: #fff;
    }
  }

  &.header-wrapper-transparent {
    background-color: transparent;
    .nav {
      .nav-item {
        color: #fff;
        &:hover {
          font-weight: 500;
        }
        a {
          color: #fff;
        }
      }
    }
    .notice-wrap {
      .swiper-slide {
        .text {
          color: #fff;
        }
      }
    }
    .login-btn {
      border: 1px solid #fff;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: #333;
      }
    }
  }
  &.header-wrapper-dark {
    border-bottom: none;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.02);
    .nav {
      .nav-item {
        color: #fff;
        &:hover {
          font-weight: 500;
        }
        a {
          color: #fff;
        }
        .sub-nav-list {
          .sub-nav-item {
            a {
              opacity: 0.8;
              color: #fff;
            }
          }
        }
      }
    }
    .notice-wrap {
      .swiper-slide {
        .text {
          color: #fff;
        }
      }
    }
    .login-btn {
      border: 1px solid #fff;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: #333;
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .header-wrapper {
    .header-container {
      width: 1440px;
      margin: 0 auto;
    }
  }
}
@media screen and (min-width: 1640px) and (max-width: 1920px) {
  .header-wrapper {
    // padding-right: 30px;
    .header-container {
      margin-left: 240px;
      margin-right: 30px;
    }
    .notice-wrap {
      margin-left: 108px;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1640px) {
  .header-wrapper {
    // padding-left: 30px;
    // padding-right: 30px;
    .header-container {
      margin-left: 30px;
      margin-right: 30px;
    }
    .logo-wrap {
      margin-right: 40px;
    }
    .notice-wrap {
      margin-left: 42px;
    }
  }
}
@media screen and(max-width: 1200px) {
  .header-wrapper {
    overflow-x: auto;
    // overflow-y: hidden;
    .header-container {
      // width: 1140px;
      // padding: 0 30px;
      margin-left: 30px;
      margin-right: 30px;
      .nav {
        .nav-item {
          margin-right: 30px;
        }
      }
    }
  }
}
@media screen and(max-width: 960px) {
  .header-wrapper {
    overflow-x: auto;
    // overflow-y: hidden;
    .header-container {
      width: 900px;
      .logo-wrap {
        margin-right: 30px;
      }
      .nav {
        .nav-item {
          margin-right: 20px;
        }
      }
      .notice-wrap {
        margin-left: 30px;
      }
    }
  }
}
</style>
