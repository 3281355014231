import Vue from 'vue'
import VueRouter from 'vue-router'
import { isClient } from '@/utils'

Vue.use(VueRouter)

export default function createRouter(context, store) {
  const hook = (to, from, next) => {
    console.log(to, from)
    console.log(store.state)
    if (store.state.isLogin) {
      next()
    } else {
      isClient(() => {
        window.lib.storage.set('nextUrl', to.path)
      })
      store.commit('openLoginModal')
      next('/intellectual-property')
    }
  }

  const routes = [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
      meta: {
        ssr: true,
        withoutLayout: true,
        hide_sidebar: true,
      },
    },
    {
      path: '/celebrity',
      name: 'celebrity',
      redirect: '/anchor',
    },
    {
      path: '/agents',
      name: 'agents',
      redirect: '/channel-join',
    },

    {
      path: '/anchor',
      name: 'Anchor',
      component: () => import('@/views/Anchor.vue'),
      meta: {
        ssr: true,
        withoutLayout: true,
        withoutFooter: true,
      },
    },
    {
      path: '/store',
      name: 'Store',
      component: () => import('@/views/Store.vue'),
      meta: {
        ssr: true,
        withoutLayout: true,
      },
    },
    {
      path: '/channel-join',
      name: 'ChannelJoin',
      component: () => import('@/views/channel-join.vue'),
      meta: {
        ssr: true,
      },
    },
    {
      path: '/partner',
      name: 'Partner',
      component: () => import('@/views/partner.vue'),
      meta: {
        ssr: true,
      },
    },
    {
      path: '/source-factory',
      name: 'SourceFactory',
      component: () => import('@/views/source-factory.vue'),
      meta: {
        ssr: true,
        withoutLayout: true,
      },
    },
    {
      path: '/scrm',
      name: 'Scrm',
      component: () => import('@/views/scrm.vue'),
      meta: {
        ssr: true,
        title: '酷管家',
        withoutLayout: true,
      },
    },
    // {
    //   path: '/about',
    //   name: 'About',
    //   component: () => import('@/views/about.vue'),
    //   meta: {
    //     ssr: true,
    //   },
    // },
    {
      path: '/recruit',
      name: 'Recruit',
      component: () => import('@/views/recruit.vue'),
      meta: {
        ssr: true,
      },
    },
    {
      path: '/news',
      name: 'News',
      component: () => import('@/views/news.vue'),
      meta: {
        ssr: true,
      },
    },
    {
      path: '/knowledge-base',
      name: 'knowledge-base',
      component: () => import('@/views/KnowledgeBase.vue'),
      meta: {
        ssr: true,
        title: '魔筷知识库',
        withoutLayout: true,
      },
    },
    {
      path: '/category/:id',
      name: 'News',
      component: () => import('@/views/news.vue'),
      meta: {
        ssr: true,
        title: '资讯列表',
      },
    },
    {
      path: '/article/:id',
      name: 'Article',
      component: () => import('@/views/article.vue'),
      meta: {
        ssr: true,
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/login.vue'),
      meta: {
        ssr: true,
        title: '登录',
        withoutLayout: true,
      },
    },
    {
      path: '/review',
      name: 'review',
      component: () => import('@/views/review.vue'),
      meta: {
        ssr: true,
        title: '主播合作',
        withoutLayout: true,
      },
    },
    {
      path: '/about-copy',
      name: 'about-copy',
      component: () => import('@/views/about-copy.vue'),
      meta: {
        ssr: true,
        title: '关于我们',
        withoutLayout: true,
      },
    },
    {
      path: '/collect-anchor',
      name: 'CollectAnchor',
      component: () => import('@/views/collect-anchor.vue'),
      meta: {
        ssr: true,
        title: '主播线索收集',
      },
    },
    {
      path: '/collect-store',
      name: 'CollectStore',
      component: () => import('@/views/collect-store.vue'),
      meta: {
        ssr: true,
        title: '商家线索收集',
      },
    },
    {
      path: '/collect-scrm',
      name: 'CollectScrm',
      component: () => import('@/views/collect-scrm.vue'),
      meta: {
        ssr: true,
        title: '酷管家线索收集',
      },
    },
    {
      path: '/collect-cloudstore',
      name: 'CollectCloudStore',
      component: () => import('@/views/collect-cloudstore.vue'),
      meta: {
        ssr: true,
        title: '云仓线索收集',
      },
    },
    {
      path: '/ai-tools',
      name: 'AiTools',
      component: () => import('@/views/ai-tools.vue'),
      meta: {
        ssr: true,
        title: '云仓线索收集',
      },
    },
    {
      path: '/intellectual-property',
      component: () => import('@/views/Intellectual-property.vue'),
      children: [
        {
          path: '',
          name: 'IntellectualHome',
          component: () => import('@/views/Intellectual-home.vue'),
          meta: {
            // hideHeader: true,
            // hideFooter: true,
            withoutLayout: true,
            hide_sidebar: true,
            ssr: false,
          },
        },
        {
          path: 'notice',
          name: 'IntellectualNotice',
          component: () => import('@/views/Intellectual-notice.vue'),
          beforeEnter: hook,
          meta: {
            withoutLayout: true,
            hide_sidebar: true,
            ssr: false,
          },
        },
        {
          path: 'complaint',
          name: 'IntellectualComplaint',
          component: () => import('@/views/Intellectual-complaint.vue'),
          beforeEnter: hook,
          meta: {
            withoutLayout: true,
            hide_sidebar: true,
            ssr: false,
          },
        },
        {
          path: 'record',
          name: 'IntellectualRecord',
          component: () => import('@/views/Intellectual-record.vue'),
          beforeEnter: hook,
          meta: {
            withoutLayout: true,
            hide_sidebar: true,
            ssr: false,
          },
        },
      ],
    },
    {
      path: '/crm-mobile',
      name: 'CrmMobile',
      component: () => import('@/views/others/crm-mobile.vue'),
      meta: {
        ssr: false,
        withoutLayout: true,
      },
    },
    {
      path: '/404',
      name: '404',
      redirect: '/',
    },
  ]

  // 阻止服务端渲染刷新报错
  // const originalPush = VueRouter.prototype.push
  // VueRouter.prototype.push = function push(location, onResolve, onReject) {
  //   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  //   return originalPush.call(this, location).catch(err => err)
  // }

  // const originalReplace = VueRouter.prototype.replace
  // VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  //   if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  //   return originalReplace.call(this, location).catch(err => err)
  // }

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
      return { x: 0, y: 0 }
    },
  })

  router.beforeEach((to, from, next) => {
    isClient(() => {
      if (to.meta.title) {
        document.title = to.meta.title
      }
      // document.title = to.meta.title || '魔筷科技'
    })

    // 如果当前页面在新系统中不存在且当前页面不存在，本地会死循环，对应的处理
    if (!from.name && !to.name) {
      next('404')
      return false
    }

    // 如果跳转的路由，新系统里没有，则location跳转
    if (to.name) {
      next()
    } else {
      console.log(context.type)
      context.type === 'server'
        ? router.$$redirect(to.path)
        : (location.href = to.path)
    }
  })

  return router
}
