import Vue from 'vue'
import Vuex from 'vuex'
// import router from '../router'

Vue.use(Vuex)

console.log(this);


export default function createStore() {
  return new Vuex.Store({
    state: {
      isLogin: typeof window === 'undefined' ? false : window.localStorage.getItem('access_token'),
      // isLogin: false,
      loginModalOpen: false,
    },
    mutations: {
      login(state) {
        state.isLogin = true;
      },
  
      logout(state) {
        window.lib.storage.rm('access_token');
        window.lib.storage.rm('refresh_token');
        state.isLogin = false;
      },
  
      openLoginModal(state) {
        document.body.style = 'overflow: hidden;';
        state.loginModalOpen = true;
      },
  
      closeLoginModal(state) {
        document.body.style = '';
        state.loginModalOpen = false;
      }
    },
    actions: {},
    modules: {
    }
  })
}
