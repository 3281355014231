/**
 *  APP_KEY: 后端给定
 *  APP_PWD: 后端给定
 *  API_URL: 请求域名
 *  H5_URL: 审核H5域名
 * */

module.exports = {
    // 联调环境
    dev: {
        // APP_KEY: '554992ad80486d1c518b47b69bfd4f66',
        // APP_PWD: '985a3c029ed29d5ead725f5803592c4c',
        APP_KEY: 'fef09fb80894406fa198a0502bafa6fb',
        APP_PWD: '4329c9c663324963b45d8329e50fc359',
        API_URL: 'http://apidev001.mockuai.com/',
        H5_URL: 'http://waptest.snapshot.m.wudizhanggui.com/',
    },
    mock: {
        APP_KEY: '554992ad80486d1c518b47b69bfd4f66',
        APP_PWD: '985a3c029ed29d5ead725f5803592c4c',
        API_URL: 'http://yapi.mockuai.com/mock/1987/',
        H5_URL: 'http://waptest.snapshot.m.wudizhanggui.com/',
    },
    // 测试环境
    test: {
        APP_KEY: '554992ad80486d1c518b47b69bfd4f66',
        APP_PWD: '985a3c029ed29d5ead725f5803592c4c',
        API_URL: 'https://apidaily.mockuai.com/',
        H5_URL: 'http://waptest.snapshot.m.wudizhanggui.com/',
    },
    // 预发环境
    wapa: {
        // APP_KEY: '554992ad80486d1c518b47b69bfd4f66',
        // APP_PWD: '985a3c029ed29d5ead725f5803592c4c',
        APP_KEY: 'fef09fb80894406fa198a0502bafa6fb',
        APP_PWD: '4329c9c663324963b45d8329e50fc359',
        API_URL: 'https://apiwapa.mockuai.com/',
        H5_URL: 'http://wapa.snapshot.m.wudizhanggui.com/',
    },
    // beta
    beta: {
        // APP_KEY: '7ff47887933bece366de5259b1e20a25',
        // APP_PWD: 'f89b45f02bac39688e329595af411a99',
        APP_KEY: 'fef09fb80894406fa198a0502bafa6fb',
        APP_PWD: '40274d6fe71f4f06808e7bb0393fcaf3',
        API_URL: 'https://mop-beta.mockuai.com/',
        H5_URL: 'https://snapshot.m.wudizhanggui.com/',
    },
    // 线上
    online: {
        APP_KEY: 'fef09fb80894406fa198a0502bafa6fb',
        APP_PWD: '40274d6fe71f4f06808e7bb0393fcaf3',
        API_URL: 'https://api.mockuai.com/',
        H5_URL: 'https://snapshot.m.wudizhanggui.com/',
    },
}