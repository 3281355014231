<template>
  <div id="app">
    <page-header></page-header>
    <div class="container">
      <router-view />
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from '@/layout/Header.vue'
import PageFooter from '@/layout/Footer.vue'
import { loadTrackJs, isClient } from '@/utils'

export default {
  components: {
    PageHeader,
    PageFooter
  },

  mounted() {
    isClient(() => {
      loadTrackJs()
    })
  },

  destroyed() {
    window.lib.storage.rm('nextUrl')
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: calc(100vh - 580px);
  overflow-x: hidden;
}

@media screen and (max-width: 1440px) {
  .container {
    min-height: calc(100vh - 460px);
  }
}
</style>
