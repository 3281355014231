var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('header',{ref:"header",staticClass:"header-wrapper",class:_vm.wrapperClass,style:(_vm.wrapperStyle),attrs:{"id":"page-header"},on:{"mouseleave":_vm.hideSubNav}},[_c('div',{staticClass:"header-container"},[_c('div',{staticClass:"logo-wrap"},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logo,"alt":""}})])]),_c('ul',{staticClass:"nav"},_vm._l((_vm.navs),function(nav,index){return _c('li',{key:index,staticClass:"nav-item",class:_vm.$route.path === nav.href ||
          (_vm.$route.path === '/source-factory' && nav.subNavIndex === 1) ||
          (nav.subs &&
            nav.subs.map(function (item) { return item.href; }).includes(_vm.$route.path))
            ? 'active'
            : '',on:{"mouseenter":function($event){return _vm.showSubNav(nav)}}},[(nav.href)?_c('a',{attrs:{"href":nav.href}},[_vm._v(_vm._s(nav.text))]):[_c('span',[_vm._v(_vm._s(nav.text))]),_c('img',{staticClass:"icon",attrs:{"src":_vm.subnavVisible && _vm.subnavIndex === nav.subNavIndex
                ? _vm.arrowUpIcon
                : _vm.arrowDownIcon,"alt":""}}),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.subnavVisible && _vm.subnavIndex === nav.subNavIndex),expression:"subnavVisible && subnavIndex === nav.subNavIndex"}],staticClass:"sub-nav-list"},_vm._l((nav.subs),function(subnav,i){return _c('li',{key:i,staticClass:"sub-nav-item"},[_c('a',{attrs:{"href":subnav.href}},[_vm._v(_vm._s(subnav.text))])])}),0)]],2)}),0)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }