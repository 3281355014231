export const loadJS = (url, callback = () => {}) => {
  const script = document.createElement('script')
  const fn = callback || function() {}
  script.type = 'text/javascript'

  script.onload = function() {
    fn()
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

export const isClient = (callback = () => {}) => {
  if (!process.env.VAPPER_ENV || process.client) {
    callback()
  }
}

// 动态添加预加载字体
export const loadFont = url => {
  const link = document.createElement('link')
  link.rel = 'preload'
  link.as = 'font'
  link.href = url
  link.crossorigin="anonymous"
  document.head.appendChild(link)
}

// 添加友盟打点脚本
export const loadTrackJs = () => {
  var _hmt = _hmt || [];
  window._hmt = _hmt;
  (function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?a2efae7ae3023d81b37c69400958443a";
    var s = document.getElementsByTagName("script")[0]; 
    s.parentNode.insertBefore(hm, s);
  })();
}