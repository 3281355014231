import { Message } from 'element-ui';

function resetApi(ajax) {
  function axiosPromise(option) {
    return new Promise((resolve, reject) => {
      function success(res) {
        if (res) {
          resolve(res);
        }
      }
      function error(err) {
        if (err) {
          if (option.emitError) {
            if (err && err.msg) {
              Message.error(err.msg);
            }
          }
          reject(error);
        }
      }

      const _success = option.success;
      option.success = function (res) {
        if (_success) {
          _success(res);
        }
        success(res);
      };
      const _error = option.error;
      option.error = function (err) {
        if (_error) {
          _error(err);
        } else {
          error(err);
        }
      };

      ajax(option);
    });
  }

  return axiosPromise;
}

export default resetApi;
